import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Input } from "utils/input";
import { useState } from "react";

const ChangeUserPasswordSchema = Yup.object().shape({
  password_first: Yup.string()
    .required()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      "Must Contain At Least 8 Characters, One Uppercase, One Lowercase"
    ),
  password_confirm: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password_first === value;
    }
  ),
});
interface PasswordChangeInfo {
  password_confirm: string;
  password_first: string;
}
interface ChangePasswordFormProps {
  submit: (password_first: PasswordChangeInfo) => void;
}
export function ChangePassword({ submit }: ChangePasswordFormProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="mx-auto w-full max-w-sm">
      <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
        Change Password
      </h2>
      <Formik
        initialValues={{ password_first: "", password_confirm: "" }}
        validationSchema={ChangeUserPasswordSchema}
        onSubmit={submit}
      >
        <Form className="space-y-6 mt-6">
          <Input
            name="password_first"
            label="Password"
            type={showPassword ? "text" : "password"}
            required
          />
          <Input
            name="password_confirm"
            label="Retype Password"
            type={showPassword ? "text" : "password"}
            required
          />
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                checked={showPassword}
                onChange={() => setShowPassword((prevState) => !prevState)}
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="comments" className="font-medium text-gray-700">
                Show password
              </label>
            </div>
          </div>
          <ul>
            <li className="text-sm font-medium text-gray-600">
              Password requirements:
            </li>
            <li className="text-xs text-gray-500">
              8 characters minimum length
            </li>
            <li className="text-xs text-gray-500">
              Contains at least 1 uppercase letter
            </li>
            <li className="text-xs text-gray-500">
              Contains at least 1 lowercase letter
            </li>
            {/* <li className="text-xs text-gray-500">
              one special character and one number:
            </li> */}
          </ul>
          <span className="block w-full rounded-md shadow-sm">
            <button
              type="submit"
              id="submitBtn"
              className="
                      w-full
                      flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-blue-600
                      hover:bg-blue-500
                      focus:outline-none focus:border-blue-700 focus:shadow-outline-blue
                      active:bg-blue-700
                      transition
                      duration-150
                      ease-in-out
                    "
            >
              Save
            </button>
          </span>
        </Form>
      </Formik>
    </div>
  );
}
