import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Input } from "utils/input";
import { useState } from "react";

const resetUserSchema = Yup.object().shape({
  verification_code: Yup.string().required(),
  password: Yup.string()
    .required()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^(?=.*\d)(?=.*[!@#$%^//\\&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  passwordConfirm: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password === value;
    }
  ),
});

interface ResetFormProps {
  onSubmit: (_: { password: string; verification_code: string }) => void;
  resendCode: VoidFunction;
  sent: boolean;
}

export function ResetForm({ onSubmit, sent, resendCode }: ResetFormProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Formik
      initialValues={{
        verification_code: "",
        password: "",
        passwordConfirm: "",
      }}
      validationSchema={resetUserSchema}
      onSubmit={onSubmit}
    >
      <Form className="space-y-4 mt-6">
        <Input
          name="verification_code"
          label="Verification code"
          type="text"
          required
        />
        <div className="flex justify-end text-sm">
          {sent ? (
            <p className="text-green-600"> Verification code sent </p>
          ) : (
            <button onClick={resendCode} className="text-blue-600">
              Resend verification code
            </button>
          )}
        </div>
        <Input
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          required
        />
        <Input
          name="passwordConfirm"
          label="Confirm Password"
          type={showPassword ? "text" : "password"}
          required
        />
        <div className="relative flex items-start">
          <div className="flex items-center h-5">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              checked={showPassword}
              onChange={() => setShowPassword((prevState) => !prevState)}
              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="comments" className="font-medium text-gray-700">
              Show password
            </label>
          </div>
        </div>
        <ul>
          <li className="text-sm font-medium text-gray-600">
            Password requirements:
          </li>
          <li className="text-xs text-gray-500">8 characters minimum length</li>
          <li className="text-xs text-gray-500">
            Contains at least 1 uppercase letter
          </li>
          <li className="text-xs text-gray-500">
            Contains at least 1 lowercase letter
          </li>
          {/* <li className="text-xs text-gray-500">
              one special character and one number:
            </li> */}
        </ul>
        <div className="mt-6">
          <span className="block w-full rounded-md shadow-sm">
            <button
              type="submit"
              id="submitBtn"
              className="
                    w-full
                    flex
                    justify-center
                    py-2
                    px-4
                    border border-transparent
                    text-sm
                    font-medium
                    rounded-md
                    text-white
                    bg-blue-600
                    hover:bg-blue-500
                    focus:outline-none focus:border-blue-700 focus:shadow-outline-blue
                    active:bg-blue-700
                    transition
                    duration-150
                    ease-in-out
                  "
            >
              Reset Password
            </button>
          </span>
        </div>
      </Form>
    </Formik>
  );
}
