import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import Slideover from "utils/slideover";
import Faq from "./faq";

export function Layout({ children }: React.PropsWithChildren<{}>) {
  const [openSlideover, setOpenSlideover] = useState(false);
  return (
    <div className="min-h-screen bg-white flex">
      <div
        className="
          flex-1 flex flex-col
          justify-center
          py-12
          px-4
          sm:px-6
          lg:flex-none lg:px-12
          xl:px-18
          w-1/3
        "
      >
        <img
          className="h-20 w-auto mx-auto"
          src="https://res.cloudinary.com/geerd/image/upload/c_scale,w_500/v1636309911/geerd/base_logo_transparent_background_z0u097.png"
          alt="GEERD"
        />
        {children}
        <p className="text-center text-gray-500 text-xs mt-2">
          &copy;2022 GEERD. Made with love in Morocco
          <span role="img" aria-label="Flag of Morocco">
            {" "}
            🇲🇦{" "}
          </span>
        </p>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://res.cloudinary.com/geerd/image/upload/c_scale,w_2000/v1667828163/authentication/pexels-boris-ulzibat-4805548_dlem9l.jpg"
          alt=""
        />
      </div>
      <button
        onClick={() => {
          setOpenSlideover(true);
        }}
        className="fixed bottom-16 right-16 rounded-full  text-blue-700 lg:text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
      >
        <QuestionMarkCircleIcon className="h-16 w-16 " aria-hidden="true" />
      </button>
      <Slideover
        panelClassName="max-w-xl"
        open={openSlideover}
        header={{
          title: "Frequently asked questions",
          description: "",
        }}
        onClose={() => setOpenSlideover(false)}
      >
        <Faq />
      </Slideover>
    </div>
  );
}
