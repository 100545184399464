import React, {
  Fragment,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ArrowsExpandIcon } from "@heroicons/react/solid";

export const ConfirmOnCloseContext = createContext<React.Dispatch<
  React.SetStateAction<boolean>
> | null>(null);

export function useConfirmOnClose(isDirty: boolean) {
  const setConfirmOnClose = useContext(ConfirmOnCloseContext);

  useEffect(() => {
    if (isDirty && setConfirmOnClose) {
      setConfirmOnClose(true);
    }
  }, [isDirty, setConfirmOnClose]);
}

export type SlideoverProps = {
  children: ReactNode;
  header?: { title: string; description: string };
  open: boolean;
  onClose(value: boolean): void;
  panelClassName?: string;
  isFullScreenDefault?: boolean;
};
export default function Slideover({
  children,
  header,
  open,
  onClose,
  panelClassName = "max-w-md",
  isFullScreenDefault = false,
}: SlideoverProps) {
  const [isFullScreen, setIsFullScreen] = useState(isFullScreenDefault);

  return (
    <>
      <Transition.Root show={Boolean(open)} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(value) => {
            onClose(value);
          }}
        >
          <div className="fixed inset-0 " />

          <div className="fixed inset-0  overflow-hidden">
            <div className="absolute inset-0   overflow-hidden">
              <div
                className={`pointer-events-none fixed inset-y-0 right-0 flex max-w-full ${
                  isFullScreen ? "" : "w-md:pl-10"
                }  `}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel
                    className={`pointer-events-auto w-screen ${panelClassName} ${
                      isFullScreen ? "!max-w-none" : ""
                    } `}
                  >
                    <div className="flex h-full overflow-x-hidden flex-col overflow-y-scroll bg-white shadow-xl">
                      {header ? (
                        <div className="bg-blue-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-base font-semibold leading-6 text-white">
                              {header.title}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center gap-x-2">
                              {/* <button
                                type="button"
                                className="rounded-md bg-blue-700 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => setIsFullScreen(!isFullScreen)}
                              >
                                <span className="sr-only">
                                  {isFullScreen ? "Minimize" : "Maximize"}
                                </span>
                                {isFullScreen ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
                                    />
                                  </svg>
                                ) : (
                                  <ArrowsExpandIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </button> */}
                              <button
                                type="button"
                                className="rounded-md bg-blue-700 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => {
                                  onClose(false);
                                }}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-blue-300">
                              {header.description}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-end items-center pt-4 pr-4">
                          <button
                            type="button"
                            className="rounded-md  text-blue-700 hover:text-blue-400 focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => onClose(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      )}
                      <div className="relative flex-1 py-6 px-4 sm:px-6">
                        {children}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
