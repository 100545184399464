import React, { Fragment } from "react";
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { Transition } from "@headlessui/react";

enum NotificationStatus {
  None,
  Success,
  Error,
};

interface Notification {
  status: NotificationStatus;
  message: string;
  duration?: number;
};

interface NotificationContext {
  notification: Notification;
  success: (msg: string, duration?: number) => void;
  error: (msg: string, duration?: number) => void;
  clear: () => void;
};

const notificationContext = React.createContext<NotificationContext>({
  notification: { status: NotificationStatus.None, message: "" },
  success: (msg: string, duration?: number) => { },
  error: (msg: string, duration?: number) => { },
  clear: () => { },
});
notificationContext.displayName = "Notification";

// -- Provider
export function NotificationProvider({ children }: React.PropsWithChildren<{}>) {
  const [notification, setNotification] = React.useState<Notification>({
    status: NotificationStatus.None,
    message: ""
  });

  // -- callbacks
  const success = (message: string, duration: number = 5000) =>
    setNotification({ status: NotificationStatus.Success, message, duration });

  const error = (message: string, duration: number = 7500) =>
    setNotification({ status: NotificationStatus.Error, message, duration });

  const clear = () =>
    setNotification({ status: NotificationStatus.None, message: "" });

  // -- clear after duration
  React.useEffect(() => {
    if (notification.status === NotificationStatus.None) return;
    const timer = setTimeout(clear, notification.duration);
    return () => clearTimeout(timer);
  }, [notification]);

  return (
    <notificationContext.Provider
      value={{
        notification,
        success,
        error,
        clear,
      }}
    >
      <NotificationView />
      {children}
    </notificationContext.Provider>
  );
}

// -- Hook
export function useNotification() {
  return React.useContext(notificationContext);
}

// -- View 
function NotificationView() {
  const { notification, clear } = useNotification();
  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={notification.status !== NotificationStatus.None}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {notification.status === NotificationStatus.Error && <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />}
                  {notification.status === NotificationStatus.Success && <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">{NotificationStatus[notification.status]}</p>
                  <p className="mt-1 text-sm text-gray-500">{notification.message}</p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={clear}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}