import * as Yup from "yup";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { useLoginUser } from "./useLoginUser";
import { useChangePassword } from "./useChangePassword";
import { Input } from "utils/input";
import { ChangePassword } from "./changePassword";
import NotFound from "../NotFound";

const LoginUserSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

export function Login() {
  const [mode, setMode] = useState("");
  const [user, setuser] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const loginUser = useLoginUser(setuser, setMode);
  const changeUserPassword = useChangePassword(user, setMode);
  const [params] = useSearchParams({ return_to: "" });

  const return_to = params.get("return_to");

  if (!return_to) {
    return <NotFound />;
  }

  const redirect = new URL(return_to ?? "").toString();
  return (
    <div className="mx-auto w-full max-w-sm">
      {mode === "NEW_PASSWORD_REQUIRED" ? (
        <ChangePassword submit={changeUserPassword} />
      ) : (
        <>
          <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
            Sign in
          </h2>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={LoginUserSchema}
            onSubmit={({ email, password }) =>
              loginUser({ email: email.toLowerCase().trim(), password })
            }
          >
            <Form className="space-y-6 mt-6">
              <Input name="email" label="Email" type="email" required />
              <Input
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                required
              />

              <div className="flex items-center justify-between">
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="comments"
                      aria-describedby="comments-description"
                      name="comments"
                      type="checkbox"
                      checked={showPassword}
                      onChange={() =>
                        setShowPassword((prevState) => !prevState)
                      }
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-700"
                    >
                      Show password
                    </label>
                  </div>
                </div>
                <div className="text-sm leading-5">
                  <Link
                    to={{
                      pathname: "/reset",
                      search: `?return_to=${encodeURIComponent(redirect)}`,
                    }}
                    className="
                      font-medium
                      text-blue-600
                      hover:text-blue-500
                      focus:outline-none focus:underline
                      transition
                      ease-in-out
                      duration-150
                    "
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
              <span className="block w-full rounded-md shadow-sm">
                <button
                  type="submit"
                  id="submitBtn"
                  className="
                      w-full
                      flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-blue-600
                      hover:bg-blue-500
                      focus:outline-none focus:border-blue-700 focus:shadow-outline-blue
                      active:bg-blue-700
                      transition
                      duration-150
                      ease-in-out
                    "
                >
                  Sign in
                </button>
              </span>
            </Form>
          </Formik>
        </>
      )}
    </div>
  );
}
