import Auth from "@aws-amplify/auth";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useNotification } from "utils/notification";

interface PasswordChangeInfo {
  password_confirm: string;
  password_first: string;
}

export function useChangePassword(user: any, setmode: any) {
  const notification = useNotification();
  const [params] = useSearchParams({ return_to: "/home" });
  const return_to = params.get("return_to");

  const login = React.useCallback(
    ({ password_first }: PasswordChangeInfo) => {
      Auth.completeNewPassword(
        user, // the Cognito User Object
        password_first // the new password
      )
        .then((user) => {
          // at this time the user is logged in if no MFA required
          Auth.currentSession()
            .then((session) => {
              const token = session.getRefreshToken().getToken();
              const redirect = new URL(return_to ?? "");
              redirect.searchParams.append("gcal_token", token);
              redirect.searchParams.append("gcal_user", user.username);
              window.location.href = redirect.href;
            })
            .catch(({ message }) => {
              notification.error(message);
            });
        })
        .catch((e) => {
          if (e.code === "NotAuthorizedException") {
            setmode("");
            notification.error("Session Expired: Try Again");
          } else {
            notification.error(e.message);
          }
        });
    },
    [user, notification, return_to, setmode]
  );

  return login;
}
